export const generateCollectionUrl = (slug, item) => {
    if (!item) {
        return `/collection/${slug}`;
    }
    const descriptionSlug = item.description
        ? item.description.split(" ").slice(0, 5).join("-").replace(/,/g, "")
        : "";
    return `/collection/${slug}/${item.identifier || ""}/${descriptionSlug}`;
};

export const generateCategoryUrl = (slug, item) => {
    if (!item) {
        return `/category/${slug}`;
    }
    return `/category/${slug}/${item.image || ""}/${item.description
        .split(" ")
        .slice(0, 5)
        .join("-")
        .replace(/,/g, "")}`;
};

export const generateBrowseUrl = (item) => {
    if (!item) {
        return `/browse`;
    }
    return `/browse/${item.image || ""}/${item.description 
        .split(" ")
        .slice(0, 5)
        .join("-")
        .replace(/,/g, "")}`;
};

export const generateItemPart = (baseUrl, item) => {
    let url = baseUrl;
    if (item.collection) {
        url = `/collection/${item.collection}`;
    }
    return `${url}/${item.identifier || item.image || ""}/${(item.description || item.prompt)
        ?.replace(/^\//g, "")
        .split(" ")
        .slice(0, 5)
        .join("-")
        .replace(/,/g, "") ?? ""}`;
};
