import { Auth } from "@supabase/ui";
import { Fragment, useEffect } from "react";
import { Dialog, Transition } from "@headlessui/react";

const Container = (props) => {
    const { user } = Auth.useUser();
    if (user) {
        return props.loggedInView;
    }
    return props.children;
};

export function AuthDialog(props) {
    const { open, setOpen, url } = props;
    return (
        <Transition.Root show={open} as={Fragment}>
            <Dialog
                as="div"
                className="relative z-10"
                onClose={() => {}}
                onClick={(event) => {
                    if (event.target?.tagName !== "BUTTON") {
                        setOpen(false);
                    }
                }}
            >
                <Transition.Child
                    as={Fragment}
                    enter="ease-out duration-300"
                    enterFrom="opacity-0"
                    enterTo="opacity-100"
                    leave="ease-in duration-200"
                    leaveFrom="opacity-100"
                    leaveTo="opacity-0"
                >
                    <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity dark:bg-gray-950 dark:bg-opacity-75" />
                </Transition.Child>

                <div className="fixed inset-0 z-10 overflow-y-auto">
                    <div className="relative flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
                        <Transition.Child
                            as={Fragment}
                            enter="ease-out duration-300"
                            enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                            enterTo="opacity-100 translate-y-0 sm:scale-100"
                            leave="ease-in duration-200"
                            leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                            leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                        >
                            <Dialog.Panel className="relative transform overflow-hidden w-full rounded-lg bg-white dark:bg-gray-900 text-left shadow-xl transition-all sm:my-8 sm:max-w-4xl">
                                <div className="p-3 sm:p-6 ">
                                    <div className="mb-6 pb-2 border-b">
                                        <h1>
                                            {props.title || "Sign up to continue downloading for free"}
                                        </h1>
                                    </div>
                                    <AuthBasic
                                        supabase={props.supabase}
                                        redirectTo={
                                            url ||
                                            "https://vectorart.ai/?redirect=/" +
                                                (props.item?.image || "")
                                        }
                                        onSignedIn={() => setOpen(false)}
                                    />
                                </div>
                            </Dialog.Panel>
                        </Transition.Child>
                    </div>
                </div>
            </Dialog>
        </Transition.Root>
    );
}

export default function AuthBasic(props) {
    const { supabase } = props;
    useEffect(() => {
        const { data: authListener } = supabase.auth.onAuthStateChange(
            (event, session) => {
                if (event === "PASSWORD_RECOVERY")
                    setAuthView("update_password");
                if (event === "USER_UPDATED") {
                    setTimeout(() => setAuthView("sign_in"), 1000);
                }
                fetch("/api/auth", {
                    method: "POST",
                    headers: new Headers({
                        "Content-Type": "application/json",
                    }),
                    credentials: "same-origin",
                    body: JSON.stringify({ event, session }),
                }).then((res) => {
                    if (event === "SIGNED_IN") {
                        props?.onSignedIn?.(session?.user);
                    }
                });
            }
        );

        return () => {
            authListener.unsubscribe();
        };
    }, []);
    return (
        <Container supabaseClient={supabase} {...props}>
            <Auth
                redirectTo={
                    props.redirectTo
                        ? "https://vectorart.ai?redirect=" + props.redirectTo
                        : "https://vectorart.ai"
                }
                supabaseClient={supabase}
                providers={["google"]}
                view={"sign_up"}
            />
        </Container>
    );
}
