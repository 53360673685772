/* eslint-disable @next/next/no-html-link-for-pages */
import { Fragment, useEffect, useState } from "react";
import { Dialog, Transition } from "@headlessui/react";

function Checkmark() {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            strokeWidth={1.5}
            stroke="currentColor"
            className="w-4 h-4"
        >
            <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M4.5 12.75l6 6 9-13.5"
            />
        </svg>
    );
}

const freeBullets = [
    "Download SVG images for free",
    "Free for personal and educational use",
    "No attribution required",
];

const premiumBullets = [
    "Create up to 1000 images/month",
    "Download in SVG vector format",
    "You own the rights to your images",
    "Commercial use allowed",
    "No attribution required",
    "Cancel any time",
];

const unlimitedBullets = [
    "Create unlimited images/month",
    "Download in SVG vector format",
    "You own the rights to your images",
    "Commercial use allowed",
    "No attribution required",
    "Cancel any time",
];

export function CardFree(props) {
    return (
        <div
            className={
                (props.className || "") +
                " transition-shadow hover:shadow-xl mr-3 mt-8 mb-8 flex flex-col rounded-lg border-gray-300 border p-8 bg-white shadow-lg dark:bg-gray-800 dark:border-gray-700 dark:text-white dark:hover:bg-gray-900 dark:hover:border-gray-800 dark:shadow-none"
            }
        >
            <div className="flex-1">
                <div className="mb-6 font-medium text-gray-700">
                    <div>Free</div>
                    {/* <div className="mt-2 text-4xl text-gray-900">&nbsp;</div> */}
                </div>
                {freeBullets.map((bullet) => {
                    return (
                        <div
                            key={bullet}
                            className="flex items-center text-sm my-3"
                        >
                            <div className="mr-2 mt-0.5 self-start text-green-600 dark:text-green-400">
                                <Checkmark />
                            </div>
                            <div>{bullet}</div>
                        </div>
                    );
                })}
            </div>
        </div>
    );
}

export function CardPremium(props) {
    return (
        <div
            className={
                (props.className || "") +
                (props.highlight ? " scale-[1.2] " : "") +
                " transition-all hover:shadow-xl hover:border-gray-400 mx-3 mt-8 mb-8 flex flex-col rounded-lg border-gray-300 border p-8 bg-white dark:bg-gray-950 dark:border-gray-800 dark:text-white dark:hover:bg-black dark:hover:border-gray-800 dark:shadow-none shadow-lg"
            }
        >
            <div className="flex-1">
                <div className="font-bold mb-6 text-secondary">
                    <div>Premium</div>
                    {/* <div className="inline-block bg-gray-950 text-white px-1 text-xs mt-3 rounded-sm font-normal">25% OFF</div> */}
                    <div className="mt-2 text-4xl text-gray-900 dark:text-white">
                        $29&nbsp;
                        <span className="text-base text-gray-700 dark:text-gray-300 font-medium">
                            /month
                        </span>
                    </div>
                    {/* <div className="line-through font-normal text-gray-400">
                        <span className="">$39/month</span>
                    </div> */}
                </div>
                {premiumBullets.map((bullet) => {
                    return (
                        <div
                            key={bullet}
                            className="flex items-center text-sm my-3"
                        >
                            <div className="mr-2 mt-0.5 self-start text-green-600 dark:text-green-400">
                                <Checkmark />
                            </div>
                            <div>{bullet}</div>
                        </div>
                    );
                })}
            </div>
            <div>
                <button
                    onClick={() => props.onSubscribe?.()}
                    type="button"
                    className="mt-6 font-bold items-center bg-secondary-dark hover:bg-secondary-darkest disabled:bg-gray-500 inline-flex w-full justify-center rounded-md border border-transparent  px-4 py-2 text-base text-white shadow-sm  focus:outline-none sm:col-start-2 sm:text-sm"
                >
                    Subscribe to Premium
                </button>
            </div>
        </div>
    );
}

export function CardUnlimited(props) {
    return (
        <div
            className={
                (props.className || "") +
                " transition-all hover:shadow-xl hover:border-gray-400 ml-3 mt-8 mb-8 flex flex-col rounded-lg border-gray-300 border p-8 bg-white  dark:bg-gray-800 dark:border-gray-700 dark:text-white  dark:hover:border-gray-800 dark:shadow-none shadow-lg"
            }
        >
            <div className="flex-1">
                <div className="font-bold mb-6 text-black dark:text-white">
                    <div>Unlimited</div>
                    <div className="mt-2 text-4xl text-gray-900 dark:text-white">
                        $99&nbsp;
                        <span className="text-base text-gray-700 dark:text-gray-300 font-medium">
                            /month
                        </span>
                    </div>
                </div>
                {unlimitedBullets.map((bullet) => {
                    return (
                        <div
                            key={bullet}
                            className="flex items-center text-sm my-3"
                        >
                            <div className="mr-2 mt-0.5 self-start text-green-600 dark:text-green-400">
                                <Checkmark />
                            </div>
                            <div>{bullet}</div>
                        </div>
                    );
                })}
            </div>
            <div>
                <button
                    onClick={() => props.onSubscribe?.()}
                    type="button"
                    className="mt-6 items-center bg-black hover:bg-gray-900 disabled:bg-gray-500 inline-flex w-full justify-center rounded-md border border-transparent  px-4 py-2 text-base font-medium text-white shadow-sm  focus:outline-none sm:col-start-2 sm:text-sm"
                >
                    Subscribe to Unlimited
                </button>
            </div>
        </div>
    );
}

export function Body(props) {
    return (
        <div className="flex md:flex-row flex-col justify-center">
            {/* <CardFree className="order-last md:order-first" /> */}
            <CardPremium
                highlight={props.highlight}
                onSubscribe={() => {
                    if (props.onSubscribe) {
                        props.onSubscribe("premium");
                    } else {
                        window.location = "/api/purchase/premium";
                    }
                }}
            />
            <CardUnlimited
                onSubscribe={() => {
                    if (props.onSubscribe) {
                        props.onSubscribe("unlimited");
                    } else {
                        window.location = "/api/purchase/unlimited";
                    }
                }}
            />
        </div>
    );
}

export function PremiumDialogBody(props) {
    return (
        <>
            <div className="text-center text-gray-700 dark:text-gray-100 my-2 font-medium">
                {props.title || "Purchase a subscription to continue creating"}
            </div>
            <div className="flex md:flex-row flex-col justify-center">
                <CardPremium
                    onSubscribe={() => {
                        if (props.onSubscribe) {
                            props.onSubscribe("premium");
                        } else {
                            window.location =
                                "/api/purchase/premium";
                        }
                    }}
                />
                <CardUnlimited
                    onSubscribe={() => {
                        if (props.onSubscribe) {
                            props.onSubscribe("unlimited");
                        } else {
                            window.location =
                                "/api/purchase/unlimited";
                        }
                    }}
                />
            </div></>
    )
}

export default function PremiumDialog(props) {
    const [open, setOpen] = useState(props.initialOpen !== undefined ? props.initialOpen : true);

    useEffect(() => {
        if (!open) {
            props?.onClose?.();
        }
    }, [open, props, props.onClose]);
    return (
        <Transition.Root show={open} as={Fragment}>
            <Dialog as="div" className="relative z-10" onClose={setOpen}>
                <Transition.Child
                    as={Fragment}
                    enter="ease-out duration-300"
                    enterFrom="opacity-0"
                    enterTo="opacity-100"
                    leave="ease-in duration-200"
                    leaveFrom="opacity-100"
                    leaveTo="opacity-0"
                >
                    <div className="fixed inset-0 bg-gray-500 bg-opacity-75 dark:bg-gray-900 dark:bg-opacity-75 transition-opacity" />
                </Transition.Child>

                <div className="fixed inset-0 z-10 overflow-y-auto">
                    <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
                        <Transition.Child
                            as={Fragment}
                            enter="ease-out duration-300"
                            enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                            enterTo="opacity-100 translate-y-0 sm:scale-100"
                            leave="ease-in duration-200"
                            leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                            leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                        >
                            <Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-ggray-100 dark:bg-gray-700 px-4 pt-2 pb-2 text-left shadow-xl transition-all sm:my-2 sm:w-full sm:max-w-4xl sm:p-6">
                                <PremiumDialogBody {...props} />
                            </Dialog.Panel>
                        </Transition.Child>
                    </div>
                </div>
            </Dialog>
        </Transition.Root>
    );
}
